<template>
  <div>
    <ejs-dialog
        ref="membershipHistoryPopup"
        header="회원권 변경 현황 상세"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        width="1300"
        height="600"
        :enableResize="false"
        @close="dialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="header-title">회원권 변경 현황 상세</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="section-body" class="section-body">
                    <ejs-grid-wrapper
                      ref="grid"
                      v-bind="gridOptions"
                      @queryCellInfo="onQueryCellInfo"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="dialogClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {commonCodesGetComName} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {bizNoFormatter, memberNoFormatter, phoneNumberFormatter} from "@/utils/formatter";
import orderBy from "lodash/orderBy";

export default {
  name: "MembershipHistoryPopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      membershipHistoryDetailList: [],
    };
  },
  computed: {
    gridOptions() {
      return {
        // allowResizing: true,
        // autoFit: true,
        width: "100%",
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowEditing: false,
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 50,
        pageSettings: { pageSize: 50 },
        dataSource: this.membershipHistoryDetailList,
        isAutoSelectRow: false,
        columns: [
          {
            field: "renameDiv",
            headerText: "변경구분",
            type: "string",
            width: 90,
            valueAccessor(field, data) {
              return commonCodesGetComName("RENAME_DIV", data[field]);
            },
          },
          {
            field: "giveTakeDiv",
            headerText: "양도/양수",
            type: "string",
            width: 90,
            valueAccessor(field, data) {
              return data[field] === 'GIVE' ? '양도' : '양수';
            },
          },
          {
            field: "cpr",
            headerText: "법인/개인",
            type: "string",
            width: 90,
            valueAccessor(field, data) {
              return data[field] === 'CPR' ? '법인' : '개인';
            },
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            width: 80,
            valueAccessor(field, data) {
              return memberNoFormatter(data[field]);
            },
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            width: 60,
          },
          {
            field: "birthDay",
            headerText: "생년월일",
            type: "string",
            width: 80,
          },
          {
            field: "renameDate",
            headerText: "변경일자",
            type: "string",
            width: 80,
          },
          {
            field: "acqsAmt",
            headerText: "취득금액",
            type: "string",
            textAlign: "right",
            width: 90,
            valueAccessor(field, data) {
              return data[field].toLocaleString();
            },
          },
          {
            field: "cprName",
            headerText: "법인명",
            type: "string",
            width: 150,
          },
          {
            field: "bizNo",
            headerText: "사업자번호",
            type: "string",
            width: 100,
            valueAccessor(field, data) {
              return bizNoFormatter(data[field]);
            },
          },
          {
            field: "hpNo",
            headerText: "핸드폰",
            type: "string",
            width: 100,
            valueAccessor(field, data) {
              return phoneNumberFormatter(data[field]);
            }
          },
          {
            field: "addr",
            headerText: "주소",
            type: "string",
            width: 150,
          },
          {
            field: "remarks",
            headerText: "변경사유",
            type: "string",
            width: 150,
          }
        ],
      };
    },
  },
  methods: {
    showPopup(membershipHistoryDetailList) {
      const defaultObjForSingleItems = membershipHistoryDetailList
          .filter(({ renameDiv }) => renameDiv === 'NEW' || renameDiv === 'OUT')
          .map(item => {
            return {
              acqsAmt: 0,
              addr: '',
              bizNo: '',
              companyAddr1: '',
              companyAddr2: '',
              cprDiv: item.cprDiv,
              cprName: item.cprName,
              homeAddr1: '',
              homeAddr2: '',
              hpNo: '',
              memberName: '',
              memberNo: '',
              renameDate: item.renameDate,
              giveTakeDiv: item.giveTakeDiv === 'GIVE' ? 'TAKE' : 'GIVE',
              renameDiv: item.renameDiv,
              renameRequestId: item.renameRequestId,
              saleNo:item.saleNo,
            };
          });


      membershipHistoryDetailList.push(...defaultObjForSingleItems);
      const orderedHistoryDetailList = orderBy(membershipHistoryDetailList, ['renameRequestId', 'giveTakeDiv'], ['asc', 'asc']);

      this.membershipHistoryDetailList = orderedHistoryDetailList.map((item, index) => {
        return {
          ...item,
          rowSpan: 2,
          isRowSpan: true,
          isLastRow: index === orderedHistoryDetailList.length - 2, // 셀이 병합되었을 경우 마지막 행에 borderBottom을 추가하기 위함, 마지막 행의 앞 행에 css를 적용해야함
        };
      });
    },
    onQueryCellInfo(args) {
      const { cell, column: { field }, data } = args;
      if(field === 'renameDiv' || field === 'renameDate') {
        args.rowSpan = data.rowSpan;
        if(data.isLastRow) {
          cell.style.borderBottom = "1px solid #e0e0e0";
        }
      }

    },
    dialogClose() {
      this.$emit("close");
    },
  },
};
</script>


<style scoped>

</style>