<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">변경일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="searchOptions"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    :allowFiltering="false"
                    :fields="commonCodeFields"
                    cssClass="body-data-dropdown"
                    :dataSource="dropDownOption.memberDiv"
                    v-model="searchOptions.memberDiv"
                ></ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명/회원번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOptions.memberNameNo"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onSearch"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원권 변경 현황</div>
              <div class="header-caption">[{{ dataSource.length }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="gridOptions"
                  @recordClick="onRecordClick"
                  @headerCellInfo="onHeaderCellInfo"
                  @queryCellInfo="onQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <MembershipHistoryPopup
        ref="membershipHistoryPopup"
        v-if="isOpenMembershipHistoryPopup"
        @close="onCloseMembershipHistoryPopup"
    />
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import {ExcelExport, Filter, Page, Resize} from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import {commonCodesGetCommonCodeByIdxAttrb, commonCodesGetComName} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {bizNoFormatter, memberNoFormatter, phoneNumberFormatter} from "@/utils/formatter";
import MembershipHistoryPopup from "@/views/transfer-of-name/popup/MembershipHistoryPopup.vue";
import orderBy from "lodash/orderBy";

export default {
  name: "MembershipHistory",
  components: {
    MembershipHistoryPopup,
    InputText,
    InputDateRange,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        from: moment('2023-01-01').startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
        to: moment().format(DATE_FORMAT_YYYY_MM_DD),
        memberDiv: "",
        memberNameNo: "",
      },
      dataSource: [],
      dropDownOption: {
        memberDiv: [],
      },
      commonCodeFields:{ text: "comName", value: "comCode" },
      isOpenMembershipHistoryPopup: false,
    };
  },
  created() {
    const memberDivList = commonCodesGetCommonCodeByIdxAttrb("MEMBER_DIV", 12, "Y");
    memberDivList.unshift({comCode: "", comName: "전체"});
    this.dropDownOption.memberDiv = memberDivList;
    this.onSearch();
  },
  computed: {
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowEditing: false,
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 20,
        pageSettings: { pageSize: 50 },
        dataSource: this.dataSource,
        columns: [
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            width: 30,
            valueAccessor(field, data) {
              return memberNoFormatter(data[field]);
            },
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            width: 30,
          },
          {
            field: "renameDiv",
            headerText: "변경구분",
            type: "string",
            width: 30,
            valueAccessor(field, data) {
              return commonCodesGetComName("RENAME_DIV", data[field]);
            },
          },
          {
            field: "renameDate",
            headerText: "변경일자",
            type: "string",
            width: 30,
          },
          {
            field: "acqsAmt",
            headerText: "취득금액",
            type: "string",
            textAlign: "right",
            width: 40,
            valueAccessor(field, data) {
              return data[field].toLocaleString();
            },
          },
          {
            field: "cprName",
            headerText: "법인명",
            type: "string",
            width: 40,
          },
          {
            field: "bizNo",
            headerText: "사업자번호",
            type: "string",
            width: 30,
            valueAccessor(field, data) {
              return bizNoFormatter(data[field]);
            },
          },
          {
            field: "hpNo",
            headerText: "핸드폰",
            type: "string",
            width: 30,
            valueAccessor(field, data) {
              return phoneNumberFormatter(data[field]);
            }
          },
          {
            field: "addr",
            headerText: "주소",
            type: "string",
            width: 150,
          },
        ],
      };
    },
  },
  methods: {
    async onSearch() {
      this.dataSource = await GolfErpAPI.getMembershipHistory({
        fromDate: this.searchOptions.from,
        toDate: this.searchOptions.to,
        memberDiv: this.searchOptions.memberDiv,
        memberNameNo: this.searchOptions.memberNameNo,
      });
    },
    onExcelButtonClicked() {
      if (this.dataSource.length === 0) {
        this.errorToast("조회된 데이터가 없습니다");
        return;
      }
      this.$refs.grid.excelExport({fileName:"회원권 현황 리스트.xlsx"});
    },
    onCloseMembershipHistoryPopup() {
      this.isOpenMembershipHistoryPopup = false;
    },
    async onRecordClick(args) {
      try {
        const { rowData: { saleNo } } = args;
        const membershipHistoryDetailList = await GolfErpAPI.getMembershipHistory({
          saleNo,
        });
        const orderedMembershipHistoryDetailList = orderBy(membershipHistoryDetailList, ['renameDate'], ['desc']);
        this.isOpenMembershipHistoryPopup = true;
        this.$nextTick(() => {
          this.$refs.membershipHistoryPopup.showPopup(orderedMembershipHistoryDetailList);
        });
      } catch (e) {
        this.errorToast("회원권 변경 현황 상세 조회 중 오류가 발생하였습니다.");
      }
    },
    onQueryCellInfo(args) {
      const { cell, column: { field } } = args;
      if(field === "memberName") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onHeaderCellInfo(args) {
      const { cell: { column: { field } }, node } = args;
      if(field === "memberName"){
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
  },
};
</script>
<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>